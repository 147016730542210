import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"

const contactPage = () => {
    return (
        <Layout>
            <br /><br /><br /><br />
            {/* Calculator */}
            <div className="bg-aspiregray pt-3 pb-5" id="cal">
                <div className="container pl-5 pr-5 ">
                    <div className="row">
                        <div className="col-md-12 text-center pb-4 pt-4">
                            <h4 className="aspireblue text-center text-Montserrat-2">EMI CALCULATOR</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 text-center pb-3 mpb-3">
                                    <span className="sign"><i class="fa fa-inr" style={{ paddingLeft: "4px" }, { paddingRight: "4px" }} aria-hidden="true"></i></span><input type="number" placeholder="Property Price" className="input" id="emi_pp" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center pb-3 mpb-3">
                                    <span className="sign"><i class="fa fa-inr" style={{ paddingLeft: "4px" }, { paddingRight: "4px" }} aria-hidden="true"></i></span><input type="number" placeholder="Down Payment" className="input" id="emi_dp" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center pb-3 mpb-3">
                                    <span className="sign"><i class="fa fa-calendar" aria-hidden="true"></i></span><input type="number" placeholder="Duration Year" className="input" id="emi_dy" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center pb-3 mpb-3">
                                    <span className="sign"><i class="fa fa-percent" aria-hidden="true"></i></span><input type="number" placeholder="Interest Rate" className="input" id="emi_ir" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center pb-1 w-100">
                                    <button className="aurum-btn mt-3 mb-5" id="emi_btn">CALCULATE INSTALMENT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-4 bg-aspireblue text-white pt-3 pb-3">
                            Monthly Payment (EMI)
                        </div>
                        <div className="col-md-4 bg-aspireblue text-white pt-3 pb-3">
                            Total Interest Payable
                        </div>
                        <div className="col-md-4 bg-aspireblue text-white pt-3 pb-3">
                            Total of Payments (Principal + Interest)
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-4 bg-white pt-3 pb-3" id="emi-in">
                            0/-
                        </div>
                        <div className="col-md-4 bg-white pt-3 pb-3" id="int-in">
                            0/-
                        </div>
                        <div className="col-md-4 bg-white pt-3 pb-3" id="tot-in">
                            0/-
                        </div>
                    </div>
                </div>
            </div>
            <script src="../Scripts/aurum_cal.js"></script>
	    <script src="https://www.kenyt.ai/botapp/ChatbotUI/dist/js/bot-loader.js" type="text/javascript" data-bot="25390341"></script>
        </Layout>
    )
}
export default contactPage
